import React, { useEffect, useState } from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from 'axios';
//files
//import FileOne from "../assets/files/eot-nl02.pdf"




const InfoPage = () => {
    
    const [newsletters, setNewsletters] = useState([])

    useEffect(() => {
        //axios.get('http://eot.netinsites.co.nz/mailchimp/GetLatestMail.php').then(response => {
        axios.get('/mailchimp/GetLatestMail.php').then(response => {

            setNewsletters(response.data)
        })

    }, [])
       
      


   
    
   
    return (

        <Layout pageClass="info-page">
        <SEO title="Info" />
        <main className="main">
            <div className="typography inner">
                <h1>Information</h1>          
                <h2>Published Articles</h2>

                <div className="articles-container">
                    <div className="article">
                        <h4>Good Vibrations</h4>
                        <h5>Our attitude and the attitude of our staff can be our competitive advantage.</h5>
                        <p>
                            <span>Article published in The channel (www.thechannel.co.nz)</span>
                            <span>October 2009</span>
                        </p>
                        
                        <a href='/thechannel-1009.pdf' target="_blank" title="View PDF">Read</a> 
                    </div>
                    <div className="article">
                        <h4>Watch out for Icebergs</h4>
                        <h5>Do your customers survive in spite of you or thrive because of you?</h5>
                        <p>
                            <span>Article published in the Channel (www.thechannel.co.nz)</span>
                            <span>November 2009</span>
                        </p>
                        <a href='/tc-1109.pdf' target="_blank" title="View PDF">Read</a>
                    </div>

                    <div className="article">
                        <h4>Expertise Sought</h4>
                        <h5>When and why you should consider outsourcing.</h5>
                        <p>
                            <span>Article published in IT Brief (www.itbrief.co.nz)</span>
                            <span>December 2009</span>
                        </p>
                        <a href='/itb-1209.pdf' target="_blank" title="View PDF">Read</a>
                    </div>

                    <div className="article">
                        <h4>Rule Overheads with an Iron Fist</h4>
                        <h5>There are many opportunities in this economy for the switched on small to medium business enterprise on which to capitilise without increasing overheads. but how do you in your business address an opportunity / issue / problem, without incurring long-term additional cost burdens? Especially when it is not clear what the outcome or result will be of the topic in question.</h5>
                        <p>
                            <span>Newsletter</span>
                        </p>
                        <a href='/expontap-nl-0809-fa.pdf' target="_blank" title="View PDF">Read</a>
                    </div>
                    <div className="article">
                        <h4>Bogged down with Time-wasters?</h4>
                        <h5>Do you have less and less time to spend on the parts of your business you enjoy doing? Do you even remember the spark, the talent, that drove you to set up your own business in the first place?</h5>
                        <p>
                            <span>Newsletter</span>
                        </p>
                        <a href='/eot-nl02-0909-v1.pdf' target="_blank" title="View PDF">Read</a>
                    </div>
                    <div className="article">
                        <h4>Kick start your own recovery</h4>
                        <h5>The signs are that we’re finally on the way up. That being the case, are you in a position to grab the opportunity? Finding the time to get that project you’ve been thinking about all year up and running is difficult. OK we understand. But if you dont start to plan right now for the new year, then it won’t happen when you get back after those lazy, hazy summer days.</h5>
                        <a href='/eot-nl03-1109-v2.pdf' target="_blank" title="View PDF">Read</a>
                    </div>

    

                </div>
               
 
            
            </div>
            
        </main>
        <div className="mailchimp-latest">
            <h1>Newsletters</h1>
            <div className="inner">
                {newsletters ? newsletters.map((e , i) => 

                <div class="newsletter" key={'newsletter-'+i}>
                    <h3><a href={e.Link} target="_blank" rel="noopener noreferrer" >{e.Title}</a></h3>
                    <p>{new Date(e.Sent).toLocaleDateString('en-GB')}</p>
                </div>

                ) : ''}
            </div>
                    
        </div>
    </Layout>

    )
}


export default InfoPage